import React from "react";
import Layout from "./src/components/Layout";
import { ThemeProvider } from "./src/components/context/ThemeContext";
import "./src/styles/global.css";

export const wrapPageElement = ({ element, props }) => {
  return (
    <ThemeProvider>
      <Layout {...props}>{element}</Layout>
    </ThemeProvider>
  );
};
