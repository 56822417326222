import React from "react";
import { AnimatePresence, motion } from "framer-motion";

const page = {
  initial: {
    opacity: 0,
  },
  animate: {
    opacity: 1,
    transition: { duration: 0.45 },
  },
  exit: { opacity: 0, transition: { duration: 0.4 } },
};

const Layout = ({ children, location }) => {
  return (
    <>
      <AnimatePresence exitBeforeEnter={false}>
        <motion.div
          className="bg-gray-10"
          key={location.pathname}
          variants={page}
          initial="initial"
          animate="animate"
          exit="exit"
        >
          {children}
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default Layout;
