import React, { useState, useEffect, createContext, useRef } from "react";
import useResizeObserver from "use-resize-observer";

export const ThemeContext = createContext({
  isDark: false,
  setIsDark: () => {},
  width: 0,
  height: 0,

  navbarBackground: false,
  setNavbarBackground: () => {},
  mobileMenuState: false,
  setMobileMenuState: () => {},
  isScrolled: false,
  setIsScrolled: () => {},
});

export const ThemeProvider = ({ children }) => {
  const [isDark, setIsDark] = useState(0);

  const mainRef = useRef(null);

  const { width, height } = useResizeObserver({ ref: mainRef });

  useEffect(() => {
    setIsDark(0);
  }, []);

  useEffect(() => {
    if (isDark) {
      document.documentElement.classList.add("dark");
    } else {
      document.documentElement.classList.remove("dark");
    }
  }, [isDark]);

  const [navbarBackground, setNavbarBackground] = useState(false);
  const [mobileMenuState, setMobileMenuState] = useState(false);

  const [isScrolled, setIsScrolled] = useState(false);
  return (
    <ThemeContext.Provider
      value={{
        isDark,
        setIsDark,
        width,
        height,

        mainRef,
        navbarBackground,
        setNavbarBackground,
        mobileMenuState,
        setMobileMenuState,
        setIsScrolled,
        isScrolled,
      }}
    >
      {children}
    </ThemeContext.Provider>
  );
};
